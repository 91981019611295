import React from "react";

import { styled } from "style";

import Section from "components/SectionWrapper";

const Wrapper = styled(Section)({
  bg: "transparent",
});

const Container = styled("div")({
  position: "relative",
  display: "grid",
  gridTemplateColumns: [
    "minmax(0px, 1fr)",
    null,
    "repeat(2, minmax(0px, 1fr))",
  ],
  columnGap: 4,
  rowGap: 5,
  alignItems: "start",
  width: "100%",
  maxWidth: "xl",
  mx: "auto",
});

function getStyle(type) {
  switch (type) {
    case "pageSectionText": {
      return {
        mb: 0,
        pl: [0, 4],
        pr: [0, 4, 0],
      };
    }
    case "pageSectionGallery": {
      return {
        mb: 0,
        mt: [0, 0, 4],
        px: [3, 4.5],
      };
    }
    case "pageSectionForm": {
      return {
        mb: 0,
        overflowY: [null, null, "auto"],
        position: [null, null, "sticky"],
        px: [3, 4.5],
        top: 4,
      };
    }
    default: {
      return { mb: 0 };
    }
  }
}

const CompoundSection = ({ children }) => (
  <Wrapper>
    <Container>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          isCompoundSectionChild: true,
          sx: getStyle(child.props._type),
        });
      })}
    </Container>
  </Wrapper>
);

export default CompoundSection;
