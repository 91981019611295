import { get } from "@theme-ui/css";

import { styled } from "style";

const SectionWrapper = styled("section")(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: "xxl",
  mx: "auto",
  mb: 6,
  bg: "white",
  [`@media (min-width: ${get(theme, "sizes.xxl")}px)`]: {
    borderRadius: "lg",
  },
}));

export default SectionWrapper;
